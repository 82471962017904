<template>
  <div class="readme-article">
    <h1 id="线索调配管理">
      一、线索调配管理
    </h1>
    <h2 id="客服分配规则">1.客服分配规则</h2>
    <p>查看客服分配规则，可自定义配置规则</p>
    <p>
      <img src="@/assets/img/student/5-1.png">
    </p>
    <h2 id="线索回收规则">2.线索回收规则</h2>
    <p>查看线索回收规则，可自定义配置规则</p>
    <p>
      <img src="@/assets/img/student/5-2.png">
    </p>
    <h2 id="公海领取规则">3.公海领取规则</h2>
    <p>查看公海领取规则，可自定义配置规则</p>
    <p>
      <img src="@/assets/img/student/5-3.png">
    </p>
    <h2 id="线索锁定规则">4.线索锁定规则</h2>
    <p>查看线索锁定规则，可自定义配置规则</p>
    <p>
      <img src="@/assets/img/student/5-4.png">
    </p>
    <h2 id="线索池领取规则">5.线索池领取规则</h2>
    <p>查看线索池领取规则，可自定义配置规则</p>
    <p>
      <img src="@/assets/img/student/5-5.png">
    </p>
  </div>
</template>

<script>
export default {
  name: "Student5-1",
  data() { 
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
</style>